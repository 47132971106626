<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="row">
        <p class="project-header mr-auto">รายชื่อนักศึกษาของรายวิชา {{ this.course.type_name }} ({{ this.course.program_code }})  ปีการศึกษา {{ this.course.year }}</p>
        <!-- <button v-if="course.prev_course == null" v-on:click="deleteAllStudent()" class="delete-button">ลบนักศึกษาทั้งหมด</button> -->
        <router-link :to="'/course/' + this.$route.params.cid +'/student/add/'" class="ml-8">
          <button class="edit-button" style="background-color:#0085ff">เพิ่มนักศึกษา</button>
        </router-link>
      </div>

      <!-- Student list (Table) -->
      <table>
        <!-- Table header -->
        <thead>
          <tr>
            <th class="w-1/12">ลำดับ</th>
            <th class="w-2/12">รหัสนักศึกษา</th>
            <th class="w-3/12">ชื่อ-นามสกุล</th>
            <th class="w-5/12">หัวข้อโครงงาน</th>
          </tr>
        </thead>
        <!-- Table data -->
        <tbody>
          <tr><td colspan="7"><hr></td></tr>
          <tr v-for="(student, index) in enroll" :key="student.id">
            <td>{{ index + 1 }}</td>
            <td>{{ student.code }}</td>
            <td>{{ student.name }}</td>
            <td v-if="student.topic_th" class="text-left">{{ student.topic_th }}<br>({{ student.topic_en }})</td>
            <td v-else class="text-center">ไม่พบข้อมูล</td>
            <td class="text-right">
              <button v-on:click="deleteStudent(student)" class="view-button mx-auto" style="background-color: red">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="white"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import { enrollList, changeTypeName } from "../mixins/other"

export default {
  name: "StudentList",
  components: {
    NavBar,
  },
  data () {
    return {
      loading: true,
      course: {
        type_name: '',
      },
      enroll: null,
      projects: null,
      role: null
    }
  },
  async created() {
    document.title = "รายชื่อนักศึกษา | Carbon" 
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role !== "STF") {
      this.$router.push("/")
    }
    this.course = await this.$store.dispatch("getCourseById", this.$route.params.cid)
    this.course_id = this.course.prev_course != null ? this.course.prev_course : this.course.id
    this.enroll = await this.$store.dispatch("getStudentByCourseId", this.course_id)
    this.projects = await this.$store.dispatch("getFilteredProject", { courses: [this.course_id] })
    enrollList(this.enroll, this.projects, this.course_id)
    this.course.type_name = changeTypeName(this.course, '')
    this.loading = false
  },
  methods: {
    async deleteStudent(user) {
      const data = { course_id: this.course_id, user_id: user.user_id }
      if (confirm("ต้องการลบ " + user.name + " (" + user.code + ") จากรายวิชานี้หรือไม่\nหากทำการลบนักศึกษา ระบบจะทำการลบรายชื่อนักศึกษารายนี้จากโครงงานที่\nนักศึกษารับผิดชอบด้วย") == true) {
        const success = await this.$store.dispatch("deleteStudentByUserId", data)
        if(success) {
          alert("ลบนักศึกษาออกจากรายวิชาเสร็จสิ้น")
          location.reload()
        }
      }
    },
    async deleteAllStudent() {
      if (confirm("ต้องการลบนักศึกษาทั้งหมดจากรายวิชานี้หรือไม่ \nระบบจะทำการลบรายชื่อนักศึกษาจากโครงงานที่นักศึกษารับผิดชอบด้วย") == true) {
        const success = await this.$store.dispatch("deleteAllStudentByCourseId", this.course_id)
        if(success) {
          alert("ลบนักศึกษาทั้งหมดออกจากรายวิชาเสร็จสิ้น")
          location.reload()
        }
      }
    }
  }
}
</script>